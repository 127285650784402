<template>
  <div class="animated fadeIn">
    <b-form
      novalidate
      @submit.prevent="onSubmit"
    >
      <div class="form-sections">
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Паспортные данные
          </h5>
          <b-row>
            <b-col md="4">
              <b-form-group
                id="passportInputGroup1"
                class="form-group-el-select required"
                label="Паспорт"
                label-for="passport-type"
              >
                <b-form-input
                  v-model.trim="$v.form.driver.passportType.$model"
                  disabled
                  :state="chkState('driver.passportType')"
                  aria-describedby="input1LiveFeedback1"
                  class="d-none"
                />
                <el-select
                  id="passport-type"
                  v-model="$v.form.driver.passportType.$model"
                  class="form-control"
                  name="passport-type"
                  placeholder="Выберите тип паспорта"
                  clearable
                  :loading="loading"
                >
                  <el-option
                    v-for="item in passportTypes"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
                <b-form-invalid-feedback id="input1LiveFeedback1">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-row>
                <b-col md="4">
                  <b-form-group
                    id="passportSeriesInputGroup2"
                    class="required"
                    label="Серия"
                    label-for="passport-series"
                  >
                    <b-form-input
                      id="passport-series"
                      v-model.trim="$v.form.driver.passportSeries.$model"
                      type="text"
                      name="passport-series"
                      :state="chkState('driver.passportSeries')"
                      aria-describedby="input1LiveFeedback2"
                      autocomplete="passport-series"
                      @keyup="chkSeriaField($event)"
                      @keydown="chkSeriaField($event)"
                      @keydown.space="(e) => e.preventDefault()"
                      @blur="chkSeriaField($event)"
                      @paste="chkSeriaField($event)"
                    />
                    <b-form-invalid-feedback id="input1LiveFeedback2">
                      Это поле обязательно для заполнения
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col md="8">
                  <b-form-group
                    id="passportNumberInputGroup3"
                    class="required"
                    label="Номер"
                    label-for="passport-series"
                  >
                    <b-form-input
                      id="passport-number"
                      v-model.trim="$v.form.driver.passportNumber.$model"
                      type="number"
                      name="passport-number"
                      :state="chkState('driver.passportNumber')"
                      aria-describedby="input1LiveFeedback3"
                      autocomplete="passport-number"
                      @keydown.space="(e) => e.preventDefault()"
                    />
                    <b-form-invalid-feedback id="input1LiveFeedback3">
                      Это поле обязательно для заполнения
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="passportIssueDateInputGroup4"
                class="required"
                label="Дата выдачи"
                label-for="passport-issue-date"
              >
                <!-- <b-form-input
                  v-model.trim="$v.form.driver.passportIssueDate.$model"
                  disabled
                  :state="chkState('driver.passportIssueDate')"
                  aria-describedby="input1LiveFeedback4"
                  class="d-none"
                /> -->
                <el-date-picker-input
                  id="passport-issue-date"
                  v-model.trim="$v.form.driver.passportIssueDate.$model"
                  type="date"
                  name="passport-issue-date"
                  format="dd.MM.yyyy"
                  :picker-options="pickerOptions"
                />
                <b-form-invalid-feedback id="input1LiveFeedback4">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-form-group
                id="passportIssuedByInputGroup5"
                class="required"
                label="Кем выдан"
                label-for="passport-issued-by"
              >
                <b-form-input
                  id="passport-issued-by"
                  v-model.trim="$v.form.driver.passportIssuedBy.$model"
                  type="text"
                  name="passport-issued-by"
                  :state="chkState('driver.passportIssuedBy')"
                  aria-describedby="input1LiveFeedback5"
                  autocomplete="passport-issued-by"
                />
                <b-form-invalid-feedback id="input1LiveFeedback5">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="surnameInputGroup6"
                class="required"
                label="Фамилия"
                label-for="surname"
              >
                <b-form-input
                  id="surname"
                  v-model.trim="$v.form.driver.surname.$model"
                  type="text"
                  name="surname"
                  :state="chkState('driver.surname')"
                  aria-describedby="input1LiveFeedback6"
                  autocomplete="surname"
                />
                <b-form-invalid-feedback id="input1LiveFeedback6">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="surnameInputGroup7"
                label="Имя"
                class="required"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model.trim="$v.form.driver.name.$model"
                  type="text"
                  name="name"
                  :state="chkState('driver.name')"
                  aria-describedby="input1LiveFeedback7"
                  autocomplete="name"
                />
                <b-form-invalid-feedback id="input1LiveFeedback7">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="surnameInputGroup8"
                label="Отчество"
                label-for="patronymic"
              >
                <b-form-input
                  id="patronymic"
                  v-model.trim="$v.form.driver.patronymic.$model"
                  type="text"
                  name="patronymic"
                  autocomplete="patronymic"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="birthdateInputGroup"
                class="required"
                label="Дата рождения"
                label-for="birth-date"
              >
                <el-date-picker-input
                  id="birthdate"
                  v-model.trim="$v.form.driver.birthDate.$model"
                  type="date"
                  format="dd.MM.yyyy"
                  :picker-options="pickerOptions"
                  name="birth-date"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="innInputGroup7"
                label="ИНН водителя"
                class="required"
                label-for="inn"
              >
                <b-form-input
                  id="inn"
                  v-model.trim="$v.form.driver.inn.$model"
                  type="text"
                  name="inn"
                  :state="chkState('driver.inn')"
                  aria-describedby="innInputGroup7"
                  autocomplete="inn"
                />
                <b-form-invalid-feedback id="innLiveFeedback7">
                  <span v-if="!$v.form.driver.inn.required">Это поле обязательно для заполнения</span>
                  <span v-if="!$v.form.driver.inn.minLength || !$v.form.driver.inn.maxLength">
                    Инн должен содержать 12 символов
                  </span>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="passportScreenFirstInputGroup16"
                class="required"
                label="Главная страница паспорта"
                label-for="passport-main-page"
              >
                <b-form-file
                  id="passport-main-page"
                  v-model.trim="$v.form.passportScreenFirst.$model"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  :state="chkState('passportScreenFirst')"
                  name="passport-main-page"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Файл не выбран"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  @input="uploadFile('passportScreenFirst')"
                />
                <b-form-invalid-feedback id="input1LiveFeedback9">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
                <div
                  v-if="form.passportScreenFirst && form.passportScreenFirst.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.passportScreenFirst.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.passportScreenFirst.file)"
                  >{{ form.passportScreenFirst.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="passportScreenSecondInputGroup17"
                class="required"
                label="Страница с пропиской"
                label-for="passport-registration-page"
              >
                <b-form-file
                  id="passport-registration-page"
                  v-model.trim="$v.form.passportScreenSecond.$model"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  :state="chkState('passportScreenSecond')"
                  name="passport-registration-page"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Файл не выбран"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  @input="uploadFile('passportScreenSecond')"
                />
                <b-form-invalid-feedback id="input1LiveFeedback10">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
                <div
                  v-if="form.passportScreenSecond && form.passportScreenSecond.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.passportScreenSecond.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.passportScreenSecond.file)"
                  >{{ form.passportScreenSecond.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="contractFilesInputGroup18"
                class="required"
                label="Документы договорных отношений водителя и работодателя"
                label-for="contract-file"
              >
                <b-form-file
                  v-model.trim="$v.form.contractFiles.$model"
                  disabled
                  :state="chkState('contractFiles')"
                  aria-describedby="input1LiveFeedback11"
                  multiple
                  class="d-none"
                />
                <b-form-file
                  id="contract-file"
                  v-model.trim="contractFiles"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  name="contract-file"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Выберите файл"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  multiple
                  @input="uploadFiles('contractFiles')"
                />
                <b-form-invalid-feedback id="input1LiveFeedback11">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
                <div
                  v-if="form.contractFiles.length > 0"
                  class="mt-2"
                >
                  Загруженные файлы:
                  <span
                    v-for="(file, index) in form.contractFiles"
                    :key="`file-${index}`"
                  >
                    <a
                      :href="'/files/' + file.file.guid"
                      target="_blank"
                      @click.prevent="downloadFile(file.file)"
                    >{{ file.file.originalName }}</a>&nbsp;<a
                      href="#"
                      class="fa fa-close text-danger"
                      @click="deleteFileFromMultipleField('contractFiles', index)"
                    />;
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="driverLicenseInputGroup9"
                class="required"
                label="Номер водительского удостоверения"
                label-for="drivers-license"
              >
                <b-form-input
                  id="drivers-license"
                  v-model.trim="$v.form.driver.driverLicense.$model"
                  type="text"
                  name="drivers-license"
                  :state="chkState('driver.driverLicense')"
                  aria-describedby="input1LiveFeedback9"
                  autocomplete="drivers-license"
                />
                <b-form-invalid-feedback id="input1LiveFeedback9">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="driverLicenseScreensGroup15"
                class="required"
                label="Водительское удостоверение"
                label-for="drivers-license-cover-page"
              >
                <b-form-file
                  v-model.trim="$v.form.driverLicenseScreens.$model"
                  disabled
                  :state="chkState('driverLicenseScreens')"
                  aria-describedby="input1LiveFeedback12"
                  multiple
                  class="d-none"
                />
                <b-form-file
                  id="drivers-license-cover-page"
                  v-model.trim="driverLicenseScreens"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  name="drivers-license-cover-page"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Выберите файл"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  multiple
                  @input="uploadFiles('driverLicenseScreens')"
                />
                <b-form-invalid-feedback id="input1LiveFeedback12">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
                <div
                  v-if="form.driverLicenseScreens.length > 0"
                  class="mt-2"
                >
                  Загруженные файлы:
                  <span
                    v-for="(file, index) in form.driverLicenseScreens"
                    :key="`file-${index}`"
                  >
                    <a
                      :href="'/files/' + file.file.guid"
                      target="_blank"
                      @click.prevent="downloadFile(file.file)"
                    >{{ file.file.originalName }}</a>&nbsp;<a
                      class="fa fa-close text-danger delete-multiple-file-button"
                      @click="deleteFileFromMultipleField('driverLicenseScreens', index)"
                    />;
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              v-if="accessType==='cargoFlow' || checkType===1 || accessType==='container'"
              md="4"
            >
              <b-form-group
                id="selfie-label"
                label="Фото водителя (селфи)"
                label-for="selfie"
              >
                <b-form-file
                  id="selfie"
                  v-model.trim="$v.form.selfie.$model"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  :state="chkState('selfie')"
                  name="selfie"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Файл не выбран"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  @input="uploadFile('selfie')"
                />
                <div
                  v-if="form.selfie && form.selfie.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.selfie.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.selfie.file)"
                  >{{ form.selfie.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              v-if="accessType==='container'"
              md="4"
            >
              <b-form-group
                id="power-of-attorney-label"
                label="Довереность от перевозчика"
                label-for="power-of-attorney"
              >
                <b-form-file
                  id="powerOfAttorney"
                  v-model.trim="$v.form.powerOfAttorney.$model"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  :state="chkState('powerOfAttorney')"
                  name="power-of-attorney"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Файл не выбран"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  @input="uploadFile('powerOfAttorney')"
                />
                <div
                  v-if="form.powerOfAttorney && form.powerOfAttorney.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.powerOfAttorney.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.powerOfAttorney.file)"
                  >{{ form.powerOfAttorney.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              v-if="accessType==='cargoFlow' || checkType===1"
              md="4"
            >
              <b-form-group
                id="instruction-label"
                label="Инструкция (подписанная водителем собственноручно)"
                label-for="instruction"
              >
                <b-form-file
                  id="instruction"
                  v-model.trim="$v.form.instruction.$model"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  :state="chkState('instruction')"
                  name="instruction"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Файл не выбран"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  @input="uploadFile('instruction')"
                />
                <div
                  v-if="form.instruction && form.instruction.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.instruction.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.instruction.file)"
                  >{{ form.instruction.file.originalName }}</a>
                </div>
                <example-document code="instruction" />
              </b-form-group>
            </b-col>
            <b-col
              v-if="accessType==='cargoFlow' || checkType===1 || accessType==='container'"
              md="4"
            >
              <b-form-group
                id="infoSheet-label"
                label="Инф. Лист (подписанный водителем собственноручно)"
                label-for="infoSheet"
              >
                <b-form-file
                  id="infoSheet"
                  v-model.trim="$v.form.infoSheet.$model"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  :state="chkState('infoSheet')"
                  name="infoSheet"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Файл не выбран"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  @input="uploadFile('infoSheet')"
                />
                <div
                  v-if="form.infoSheet && form.infoSheet.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.infoSheet.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.infoSheet.file)"
                  >{{ form.infoSheet.file.originalName }}</a>
                </div>
                <example-document code="infoSheet" />
              </b-form-group>
            </b-col>
            <b-col
              v-if="accessType==='cargoFlow' || checkType===1"
              md="4"
            >
              <b-form-group
                id="criminalZonesSheet-label"
                label="Лист криминогенных зон (подписанный водителем собственноручно)"
                label-for="criminalZonesSheet"
              >
                <b-form-file
                  id="criminalZonesSheet"
                  v-model.trim="$v.form.criminalZonesSheet.$model"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  :state="chkState('criminalZonesSheet')"
                  name="criminalZonesSheet"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Файл не выбран"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  @input="uploadFile('criminalZonesSheet')"
                />
                <div
                  v-if="form.criminalZonesSheet && form.criminalZonesSheet.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.criminalZonesSheet.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.criminalZonesSheet.file)"
                  >{{ form.criminalZonesSheet.file.originalName }}</a>
                </div>
                <example-document code="criminalZonesSheet" />
              </b-form-group>
            </b-col>
            <b-col
              v-if="accessType==='cargoFlow' || checkType===1"
              md="12"
              class="bold"
            >
              Важно! Все пункты в информационном листе, инструкции, листе криминогенных зон обязательны для
              заполнения и подписания водителем, кроме данных о перевозчике и направлении рейса.
              Не заполненные и не подписанные водителем документы не будут приниматься к проверке
            </b-col>
            <b-col
              v-else-if="accessType==='container'"
              md="12"
              class="bold"
            >
              Важно! Все пункты в информационном листе обязательны для заполнения и подписания водителем,
              кроме данных о перевозчике и направлении рейса.
              Не заполненный и не подписанный водителем информационный лист я не будут приниматься к проверке.
            </b-col>
          </b-row>
        </div>
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Контактная информация<span class="text-danger">*</span>
          </h5>
          <b-form-group label="Телефоны"
          >
        <multiple-phones-input
            v-model.trim="form.driverPhones"
          />
      </b-form-group>
        </div>
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Данные по допускам
          </h5>
          <b-row>
            <b-col md="4">
              <b-form-checkbox
                id="access-auto"
                v-model="$v.form.driver.driverAccessAuto.$model"
                name="access-auto"
                disabled
                @change="accessAutoDependency"
              >
                Допуск Авто
              </b-form-checkbox>
            </b-col>
            <b-col md="4">
              <b-form-checkbox
                id="access-container"
                v-model="$v.form.driver.driverAccessContainer.$model"
                name="access-container"
                disabled
              >
                Допуск Контейнер
              </b-form-checkbox>
            </b-col>
            <b-col md="4">
              <b-form-checkbox
                id="access-cargo-flow"
                v-model="$v.form.driver.driverAccessCargoFlow.$model"
                name="access-cargo-flow"
                disabled
              >
                Допуск Грузопровод
              </b-form-checkbox>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="fixed-buttons">
        <b-button
          type="button"
          variant="secondary"
          class="mr-2"
          :class="{'loading' : loading}"
          @click="$router.go(-1)"
        >
          Назад
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          :disabled="$v.form.driver.$invalid || $v.form.$invalid || loading || !validPhones || (!form.driver.driverAccessAuto && !form.driver.driverAccessContainer && !form.driver.driverAccessCargoFlow) || !form.contractFiles.length > 0 || !form.driverLicenseScreens.length > 0"
          :class="{'loading' : loading}"
        >
          Создать
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import ElDatePickerInput from '../../components/ElDatePickerInput.vue';
import {validationMixin} from 'vuelidate';
import {required, minLength, maxLength} from 'vuelidate/lib/validators';
import notifications from '../../components/mixins/notifications';
import {contractorsDriverCreate, contractorsDriverToCheck, documentCreate, passportTypesList, downloadFile} from '../../services/api';
import moment from 'moment';
import multiplePhonesInput from '../Forms/MultiplePhonesInput';
import ExampleDocument from '@/views/ExampleDocuments/ExampleDocument';

export default {
  name: 'DriversAdd',
  components: {multiplePhonesInput, ElDatePickerInput, ExampleDocument},
  mixins: [validationMixin, notifications],
  props: {
    checkType: {type: [Number, null], default: null, required: false},
    checkLocation: {type: [Number, null], default: null, required: false},
    accessType: {type: [String, null], default: null, required: false},
  },
  data() {
    return {
      passportTypes: [],
      contractFiles: [],
      driverLicenseScreens: [],
      driverCheckLocations: [],
      form: {
        driver: {
          passportType: null,
          passportSeries: '',
          passportNumber: '',
          passportIssueDate: '',
          passportIssuedBy: '',
          surname: '',
          name: '',
          patronymic: '',
          birthDate: '',
          driverLicense: '',
          driverAccessAuto: false,
          driverAccessContainer: false,
          driverAccessCargoFlow: false,
          inn: '',
        },
        passportScreenFirst: null,
        passportScreenSecond: null,
        contractFiles: [],
        driverLicenseScreens: [],
        driverPhones: [{
          countryCode: '',
          areaCode: '',
          localNumber: '',
          additionalNumber: '',
        }],
        attorneys: [],
        instruction: null,
        infoSheet: null,
        criminalZonesSheet: null,
        powerOfAttorney: null,
        selfie: null,
      },
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      loading: false,
    };
  },
  computed: {
    formStr() {
      const calculationFormData = Object.assign({}, this.form);
      calculationFormData.driver.passportIssueDate = moment(calculationFormData.driver.passportIssueDate).format('YYYY-MM-DD');
      calculationFormData.driver.birthDate = moment(calculationFormData.driver.birthDate).format('YYYY-MM-DD');
      calculationFormData.attorneys.map((attorney) => {
        attorney.date = moment(attorney.date).format();
        attorney.dateEnd = moment(attorney.dateEnd).format();
        attorney.dateStart = moment(attorney.dateStart).format();
        return attorney;
      });
      calculationFormData.checkLocation = this.checkLocation? {id: this.checkLocation} : null;
      calculationFormData.checkType = this.checkType;
      return calculationFormData;
    },
    isValid() {
      return (!this.$v.form.driver.$anyError && !this.$v.form.$anyError);
    },
    validPhones() {
      let valid = true;
      this.form.driverPhones.forEach((driverPhone) => {
        if (!driverPhone.countryCode || !driverPhone.areaCode || !driverPhone.localNumber) {
          valid = false;
        }
      });
      return valid;
    },
  },
  watch: {
    accessType: {
      handler: function(newVal) {
        if (newVal === 'auto') {
          this.form.driver.driverAccessAuto = true;
        } else {
          this.form.driver.driverAccessAuto = false;
          if (newVal === 'container') {
            this.form.driver.driverAccessContainer = true;
            this.form.driver.driverAccessCargoFlow = false;
          } else if (newVal === 'cargoFlow') {
            this.form.driver.driverAccessCargoFlow = true;
            this.form.driver.driverAccessContainer = false;
          }
        }
      },
      deep: true,
    },
  },
  validations: {
    form: {
      driver: {
        passportType: {
          required,
        },
        passportSeries: {
          required,
        },
        passportNumber: {
          required,
        },
        passportIssueDate: {
          required,
        },
        passportIssuedBy: {
          required,
        },
        surname: {
          required,
        },
        name: {
          required,
        },
        patronymic: '',
        birthDate: {
          required,
        },
        driverLicense: {
          required,
        },
        driverAccessAuto: '',
        driverAccessContainer: '',
        driverAccessCargoFlow: '',
        inn: {
          required,
          minLength: minLength(12),
          maxLength: maxLength(12),
        },
      },
      passportScreenFirst: {
        required,
      },
      passportScreenSecond: {
        required,
      },
      contractFiles: {
        required,
      },
      driverLicenseScreens: {
        required,
      },
      selfie: '',
      instruction: '',
      infoSheet: '',
      criminalZonesSheet: '',
      powerOfAttorney: '',
    },
  },
  mounted() {
    if (this.accessType === 'auto') {
      this.form.driver.driverAccessAuto = true;
    } else {
      this.form.driver.driverAccessAuto = false;
      if (this.accessType === 'container') {
        this.form.driver.driverAccessContainer = true;
      } else if (this.accessType === 'cargoFlow') {
        this.form.driver.driverAccessCargoFlow = true;
      }
    }
    this.passportTypesList();
  },
  methods: {
    downloadFile,
    addNewAttorneyFields() {
      this.form.attorneys.push({
        number: '',
        date: '',
        dateStart: '',
        dateEnd: '',
        file: '',
      });
    },
    async passportTypesList(params) {
      this.loading = true;
      const response = await passportTypesList(params);
      if (response && response.status === 200) {
        this.passportTypes = response.data.items.reduce((arr, type) => {
          if (type.type !== 'Паспорт Украины') {
            arr.push({
              value: type.id,
              text: type.type,
            });
          }
          return arr;
        }, []);
      }
      this.loading = false;
    },
    phoneValidate(e) {
      if (/_/.test(e.$model)) {
        e.$model = '';
      }
    },
    accessAutoDependency(value) {
      if (value) {
        this.form.driver.driverAccessContainer = true;
        this.form.driver.driverAccessCargoFlow = true;
      }
    },
    chkSeriaField(e) {
      this.form.driver.passportSeries = e.currentTarget.value.replace(/\s+/g, '').toUpperCase();
    },
    async onSubmit() {
      if (this.validate()) {
        this.loading = true;
        const response = await contractorsDriverCreate(this.$store.state.user.ownerId, this.formStr);
        if (response && response.status === 201) {
          if (typeof response.data.status !== 'undefined') {
            this.showWarn('Не удалось добавить водителя, некоторые из введенных данных неверны. Обратитесь в службу тех.поддержки по телефону или в чате на сайте');
            this.$bvModal
              .msgBoxOk('Предупреждение! Водитель уже есть в системе, поэтому данные будут отправлены на повторную проверку. По результатам проверки статус водителя будет изменён.', {
                okTitle: 'Хорошо',
              })
              .then((confirm) => {
                this.$router.push({path: '/drivers'});
              });
          } else {
            if (!response.data.driver.status) {
              this.showSuccess('Водитель был создан');
              this.$bvModal
                .msgBoxConfirm('Вы успешно создали Водителя, хотите отправить его на проверку логисту?', {
                  okTitle: 'Да',
                  cancelTitle: 'Нет',
                })
                .then((confirm) => {
                  if (confirm) {
                    this.contractorsDriverToCheck(response.data.id);
                  } else {
                    this.$router.push({path: `/contractors/id${this.$store.state.user.ownerId}/drivers/id${response.data.id}`});
                  }
                });
            } else {
              this.showSuccess('Водитель был создан');
              this.$router.push({path: `/contractors/id${this.$store.state.user.ownerId}/drivers/id${response.data.id}`});
            }
          }
        }
        this.loading = false;
      }
    },
    async contractorsDriverToCheck(driverId) {
      this.loading = true;
      const response = await contractorsDriverToCheck(this.$store.state.user.ownerId, driverId);
      if (response && response.status === 200) {
        this.showSuccess('Водитель отправлен на первичную проверку');
        this.$router.push({path: `/contractors/id${this.$store.state.user.ownerId}/drivers/id${driverId}`});
      }
      this.loading = false;
    },
    chkState(val) {
      if (val.indexOf('.') !== -1) {
        const parameters = val.split('.');
        const field = this.$v.form[parameters[0]][parameters[1]];
        if (!field.$dirty) {
          return null;
        } else return !field.$invalid;
      } else {
        const field = this.$v.form[val];
        if (!field.$dirty) {
          return null;
        } else return !field.$invalid;
      }
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some((child) => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
    uploadFile(key) {
      const file = this.form[key];
      if (file) {
        if (file.size >= 15 * 1024 * 1024) {
          this.showError('Файл слишком большой. Максимальный объем загружаемого файла не должен превышать 15MB.');
        } else {
          this.getBase64(file).then((data) => {
            this.loading = true;
            const upload = {
              base64file: data,
              originalName: file.name,
            };
            this.licenseScreenCreate(key, upload);
          });
        }
      }
    },
    async licenseScreenCreate(key, upload) {
      this.loading = true;
      const response = await documentCreate(upload);
      if (response && response.status === 201) {
        this.form[key] = response.data;
        this.showSuccess('Документ загружен');
      }
      this.loading = false;
    },
    uploadAttorneyFile(key) {
      const file = this.form.attorneys[key].file;
      if (file.size >= 15 * 1024 * 1024) {
        this.showError('Файл слишком большой. Максимальный объем загружаемого файла не должен превышать 15MB.');
      } else {
        this.getBase64(file).then((data) => {
          this.loading = true;
          const upload = {
            base64file: data,
            originalName: file.name,
          };
          this.attorneyScreenCreate(key, upload);
        });
      }
    },
    async attorneyScreenCreate(key, upload) {
      this.loading = true;
      const response = await documentCreate(upload);
      if (response && response.status === 201) {
        this.form.attorneys[key].file = response.data;
        this.showSuccess('Документ загружен');
      }
      this.loading = false;
    },
    uploadFiles(key) {
      const files = this[key];
      this[key] = [];
      files.forEach((file) => {
        if (file) {
          if (file.size >= 15 * 1024 * 1024) {
            this.showError('Файл слишком большой. Максимальный объем загружаемого файла не должен превышать 15MB.');
          } else {
            this.getBase64(file).then((data) => {
              this.loading = true;
              const upload = {
                base64file: data,
                originalName: file.name,
              };
              this.multipleFieldFilesCreate(key, upload);
            });
          }
        }
      });
    },
    async multipleFieldFilesCreate(key, upload) {
      this.loading = true;
      const response = await documentCreate(upload);
      if (response && response.status === 201) {
        this.showSuccess('Документ загружен');
        this.form[key].push(response.data);
      }
      this.loading = false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    deleteFileFromMultipleField(field, index) {
      this.form[field].splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
.bold {
  font-weight: bold;
}
</style>
